import {Inject, Injectable} from '@angular/core';
import * as _ from 'lodash';
import {APP_CONFIG, AppConfig} from 'src/app/app-config.module';
import {TranslationService} from './root/translation/translation.service';

@Injectable({
    providedIn: 'root'
})
export class NavigationsService {

    constructor(
        private translationService: TranslationService,
        @Inject(APP_CONFIG) public config: AppConfig
    ) { }


    // STATIC PAGES - UNCOMMENT IF NEEDED BACK
    //   getUnloggedNavigationItems() {
    //     return [
    //       {
    //           label: 'Start',
    //           link: '/'
    //       },
    //       {
    //           label: this.translationService.websiteNavigation.aboutUs,
    //           link: '/about-us'
    //       },
    //       {
    //           label: this.translationService.websiteNavigation.financingSolutions,
    //           link: '/financing-solutions'
    //       },
    //       {
    //           label: this.translationService.websiteNavigation.procedure,
    //           link: '/procedure'
    //       },
    //       {
    //           label: this.translationService.websiteNavigation.partners,
    //           link: '/partners'
    //       },
    //       {
    //           label: this.translationService.websiteNavigation.mission,
    //           link: '/mission'
    //       }
    //   ];
    //   }

    getUnloggedNavigationItems() {
        return [
            // {
            //     label: $localize`financing & Subsidies`,
            //     link: `${this.config.landing_URL}finanzierungen-foerdermittel`
            // },
            // {
            //     label: $localize`references`,
            //     link: `${this.config.landing_URL}referenzen`
            // },
            // {
            //     label: $localize`partner`,
            //     link: `${this.config.landing_URL}partner`
            // },
            // {
            //     label: $localize`About us`,
            //     link: `${this.config.landing_URL}uber-uns`
            // },
        ];
    }


    getPortalNavigationItems(account) {
        return [
            // {
            //     label: 'Start',
            //     link: '/start',
            //     tutorialStep: 4,
            //     tutorialText: this.translationService.tutorialTexts.startLink,
            // },
            // {
            //     label: 'Dashboard',
            //     link: '/dashboard',
            //     forRoles: 'ROLE_FINMATCH'
            // },
            {
                label: this.translationService.websiteNavigation.inquiries,
                link: '/inquiry/list',
                tutorialStep: 3,
                tutorialText: this.translationService.tutorialTexts.inquiriesLink,
            },
            // {
            //     label: this.translationService.websiteNavigation.notifications,
            //     link: '/notifications',
            //     disabled: true
            // },
            {
                label: this.translationService.websiteNavigation.companies,
                link: '/settings/companies',
                separator: true,
                forRoles: [
                    'ROLE_FINMATCH',
                    'ROLE_BROKER'
                ],
                matchAnyRole: true,
            },
            {
                label: this.translationService.websiteNavigation.banks,
                link: '/settings/banks',
                forRoles: 'ROLE_FINMATCH'
            },
            {
                label: account.isBroker()
                    ? this.translationService.websiteNavigation.subbrokers
                    : this.translationService.websiteNavigation.brokers,
                link: '/settings/brokers',
                forRoles: ['ROLE_FINMATCH', 'ROLE_BROKER'],
                matchAnyRole: true,
                mouseOverMenuLinks: {
                    forRoles: ['ROLE_FINMATCH'],
                    content: [
                        {
                            label: $localize`Approvals`,
                            link: '/inquiry/list',
                            queryParams: {status: 'WAITING_FOR_APPROVAL'}
                        }
    ]
                }
            },
            {
                label: this.translationService.settingsUserTabMap['ROLE_FINANCIAL'],
                link: '/settings/bank-and-users',
                forRoles: 'ROLE_FINANCIAL'
            },
            {
                label: this.getSettingsTabInfo(account),
                link: '/settings/users',
                forRoles: [
                    ['ROLE_FINMATCH'],
                    ['ROLE_MASTER', 'ROLE_BROKER'],
                    ['ROLE_MASTER', 'ROLE_FINANCIAL_UNIT'],
                    ['ROLE_MASTER', 'ROLE_COMPANY']
                ],
                matchAnyRole: true,
                tutorialStep: 2,
                tutorialText: this.translationService.tutorialTexts.companyUsersLink,
            }
        ];
    }

    getSettingsTabInfo(account) {

        let settingsLabel: string;

        if (_.includes(account.authorities, 'ROLE_FINMATCH')) {
            settingsLabel = this.translationService.settingsUserTabMap['ROLE_FINMATCH'];
        } else if (_.includes(account.authorities, 'ROLE_BROKER')) {
            settingsLabel = this.translationService.settingsUserTabMap['ROLE_BROKER'];
        } else if (_.includes(account.authorities, 'ROLE_COMPANY')) {
            settingsLabel = this.translationService.settingsUserTabMap['ROLE_COMPANY'];
        } else if (_.includes(account.authorities, 'ROLE_FINANCIAL')) {
            settingsLabel = this.translationService.settingsUserTabMap['ROLE_FINANCIAL'];
        } else if (_.includes(account.authorities, 'ROLE_FINANCIAL_UNIT') && _.includes(account.authorities, 'ROLE_MASTER')) {
            settingsLabel = this.translationService.settingsUserTabMap['ROLE_FINANCIAL_UNIT'];
        }

        return settingsLabel;
    }
}

