import {Injectable} from '@angular/core';
import {BrokerInquirySubmissionType, BrokerPartnerModel} from '../../../models/BrokerModel';
import {EquityType, InquiryModuleKey, InquiryState, ProductBankOfferStatus} from '../../../models/InquiryModel';
import {BuildingTypeOfUse} from '../../../models/real-estates.model';
import {
    FinancingType,
    InquiryModuleType,
    PropertyModuleType,
    RealEstateModuleType
} from '../../inquiry/inquiry-configuration.service';
import { TeaserWidget } from '../../inquiry/teaser/inquiry-teaser.service';
import {BankType, InstituteType} from '../../provided/banks.service';

export interface LabelsMap {
    [value: string]: string;
}

@Injectable({
    providedIn: 'root'
})
export class TranslationService {
    other = $localize`Other`;
    toastMessages: LabelsMap = {
        emailSent: $localize`Email has been sent`,
        googleRecaptchaErrorHeader: $localize`reCaptcha error`,
        googleRecaptchaErrorMessage: $localize`Connection with remote reCaptcha service failed`,
    };
    administrators = $localize`Administrators`;
    matchesCompanyAddress = $localize`Matches company address`;
    differsFromCompanyAddress = $localize`Differs from company address`;
    emailInvalid = $localize`Not a valid email`;
    invalidYear = $localize`Invalid year`;
    invalidNumber = $localize`Invalid number`;
    wrongFormat = $localize`Wrong format`;
    postCodeBankCode = $localize`Bank already defined`;
    passwordNoMatch = $localize`Confirmation password doesn\'t match'`;
    passwordCharactersValidation = $localize`Password must contain at least one upper case character, one number and one special character`;
    passwordMinMax = $localize`Password must have between 10 and 128 characters`;
    fieldRequired = $localize`This field is required`;
    futureDateError = $localize`Select date in future`;
    lessThanOrEq = $localize`The number should be less than other number`;
    greaterThanOrEq = $localize`The number should be greater than other number`;
    equityBiggerThanVolume = $localize`Value cannot be bigger than Investment Volume`;
    unassigned = $localize`Unassigned`;
    confirmationYesButton = $localize`Confirm`;
    confirmationNoButton = $localize`Cancel`;
    disbursementBiggerThanRepayment = $localize`The date cannot be later than repayment start date`;
    fixedInterestHigherThanDuration = $localize`The fixed interest rate period must not be longer than the financing duration.`;
    allowanceForDepraciationHigherThanDuration = $localize`The allowance for depreciation must not be longer than the financing duration.`;
    repaymentLowerThanDisbursement = $localize`The date cannot be before date of disbursement`;
    disbursementBeforeInquiryCreation = $localize`Date must be later than date of inquiry creation`;
    total = $localize`Total`;
    changeQuantityTooltip = $localize`To change the quantity please click on "back" and select the applicable scenario`;
    multipleMachines = $localize`Multiple machines`;
    modulePropertyTypeChangingConfirmation = $localize`Are you sure you want to change the mode of capturing the property details? In this case you lose all data already provided for the module "property" as well as the financial requirements already provided for it.`;
    moduleEstateTypeChangingConfirmation = $localize`Are you sure you want to change the mode of capturing the real estate details? In this case you lose all data already provided for the module "real estate" as well as the financial requirements already provided for it.`;
    machineScenarionChangingConfirmation = $localize`Are you sure you want to change the mode of capturing the machine details? In this case you lose all data already provided for the module "machine".`;
    losingUnsavedFormConfirmation = $localize`Are you sure you want to quit this page? If you quit this page all unsaved entries for the current form are deleted irrevocably.`;
    removingTrancheOfferConfirmation = $localize`Are you sure you want to irrevocably delete your input made for this tranche?`;
    removingTrancheOfferHeader = $localize`Delete Input - Confirmation`;

    discardBankChangesHeader = $localize`Discard changes - Confirmation`;
    discardBankChangesConfirmationMsg = $localize`Do you really want to irrevocably discard the changes made to this matching profile?`;

    changeInstituteTypeHeader = $localize`Change institute type - Confirmation`;
    changeInstituteTypeText = $localize`Do you really want to change the currently selected institute type? All input captured for the current matching profile will be irrevocably deleted.`;

    removeStateConfirmationHeader = $localize`Remove State - Confirmation`;
    removeFinProductModalHeader = $localize`Delete Tranche - Confirmation`;
    removeFinProductConfirmation = $localize`Do you really want to irrevocably delete this tranche?`;
    changingFinancingProductConfirmation = $localize`Are you sure you want to change the financing type for this tranche? In this case you lose all data already provided for it.`;
    changingFinancingProductModalHeader = $localize`Change Financing Type - Confirmation`;
    registerRoles = [
        {value: 'ROLE_COMPANY', viewValue: $localize`Corporate`},
        {value: 'ROLE_FINANCIAL', viewValue: $localize`Bank`}
    ];
    legalFormsSelect = [
        {value: 'stockCompany', viewValue: $localize`AG`},
        {value: 'europeanSociety', viewValue: $localize`SE`},
        {value: 'limitedLiabilityCompany', viewValue: $localize`GmbH`},
        {value: 'limitedPartnership', viewValue: $localize`KG`},
        {
            value: 'limitedLiabilityPartnership',
            viewValue: $localize`GmbH & Co. KG`
        },
        {value: 'registeredTrader', viewValue: $localize`e.K.`},
        {value: 'registeredAssociation', viewValue: $localize`e.V.`},
        {value: 'individualCompany', viewValue: $localize`Individual Company`},
        {value: 'cooperativeSociety', viewValue: $localize`eG`},
        {value: 'generalPartnershipSociety', viewValue: $localize`OHG`},
        {value: 'civilLawPartnership', viewValue: $localize`GbR`},
        {value: 'other', viewValue: $localize`Others`}
    ];
    salesClassSelect = [
        {value: 'million0to5', viewValue: $localize`EUR/USD/CHF 0-5 million`},
        {value: 'million5to10', viewValue: $localize`EUR/USD/CHF 5-10 million`},
        {value: 'million10to50', viewValue: $localize`EUR/USD/CHF 10-50 million`},
        {value: 'million50to100', viewValue: $localize`EUR/USD/CHF 50-100 million`},
        {value: 'million100over', viewValue: $localize`over EUR/USD/CHF 100 million`}
    ];
    bankAcceptedTurnovers = [
        ...this.salesClassSelect,
    ];
    businessClassSelect = [
        {value: '0103agricultureForestryAndFishing', viewValue: $localize`01-03 Agriculture, forestry and fishing`},
        {value: '0509miningAndQuarrying', viewValue: $localize`05-09 Mining and quarrying`},
        {value: '1033manufacturing', viewValue: $localize`10-33 Manufacturing`},
        {
            value: '35electricityGasSteamAndAirConditioningSupply',
            viewValue: $localize`35 Electricity, gas, steam and air conditioning supply`
        },
        {
            value: '3639waterSupplySewerageWasteManagementAndRemediationActivities',
            viewValue: $localize`36-39 Water supply; sewerage, waste management and remediation activities`
        },
        {value: '4143construction', viewValue: $localize`41-43 Construction`},
        {
            value: '4547wholesaleAndRetailTradeRepairOfMotorVehiclesAndMotorcycles',
            viewValue: $localize`45-47 Wholesale and retail trade; repair of motor vehicles and motorcycles`
        },
        {value: '4953transportationAndStorage', viewValue: $localize`49-53 Transportation and storage`},
        {
            value: '5556accommodationAndFoodServiceActivities',
            viewValue: $localize`55-56 Accommodation and food service activities`
        },
        {value: '5863informationAndCommunication', viewValue: $localize`58-63 Information and communication`},
        {
            value: '6466financialAndInsuranceActivities',
            viewValue: $localize`64-66 Financial and insurance activities`
        },
        {value: '68realEstateActivities', viewValue: $localize`68 Real estate activities`},
        {
            value: '6975professionalScientificAndTechnicalActivities',
            viewValue: $localize`69-75 Professional, scientific and technical activities`
        },
        {
            value: '7782administrativeAndSupportServiceActivities',
            viewValue: $localize`77-82 Administrative and support service activities`
        },
        {
            value: '84publicAdministrationAndDefenceCompulsorySocialSecurity',
            viewValue: $localize`84 Public administration and defence; compulsory social security`
        },
        {value: '85education', viewValue: $localize`85 Education`},
        {
            value: '8688humanHealthAndSocialWOrkActivities',
            viewValue: $localize`86-88 Human health and social work activities`
        },
        {value: '9093artsEntertainmentAndRecreation', viewValue: $localize`90-93 Arts, entertainment and recreation`},
        {value: '9496otherServiceActivities', viewValue: $localize`94-96 Other service activities`},
        {
            value: '9798activitiesOfHouseholdsAsEmployersUndifferentiatedGoodsAndServicesProducingActivitiesOfHouseholdsForOwnUse',
            viewValue: $localize`97-98 Activities of households as employers; undifferentiated goods- and services-producing activities of households for own use`
        },
        {
            value: '99activitiesOfExtraTerritorialOrganisationAndBodies',
            viewValue: $localize`99 Activities of extraterritorial organisations and bodies`
        },
    ];
    equityOptions: { value: EquityType, viewValue: string }[] = [
        {
            value: EquityType.equity,
            viewValue: $localize`Equity capital`
        },
        {
            value: EquityType.mezzanine,
            viewValue: $localize`Mezzanine capital`
        },
    ];
    salutationSelect: LabelsMap = {
        empty: $localize`Not declared`,
        mr: $localize`Mr.`,
        mrs: $localize`Mrs.`
    };
    salutationSelectOptions: { value: any, viewValue: string }[] = [
        {value: 'empty', viewValue: this.salutationSelect.empty},
        {value: 'mr', viewValue: this.salutationSelect.mr},
        {value: 'mrs', viewValue: this.salutationSelect.mrs}
    ];
    personalTitleSelect = [
        {value: 'empty', viewValue: $localize`No Title`},
        {value: 'dr', viewValue: $localize`Dr.`},
        {value: 'prof', viewValue: $localize`Prof.`}
    ];
    roleInCompanyMap: LabelsMap = {
        managingDirector: $localize`Managing Director`,
        boardDirectors: $localize`Board of Directors`,
        authorizedOfficer: $localize`Authorized Officer`,
        owner: $localize`Owner`,
        managingPartner: $localize`Managing Partner`,
        employee: $localize`Employee`,
        other: $localize`Others`
    };
    roleInCompanySelect = [
        {value: 'managingDirector', viewValue: $localize`Managing Director`},
        {value: 'boardDirectors', viewValue: $localize`Board of Directors`},
        {value: 'authorizedOfficer', viewValue: $localize`Authorized Officer`},
        {value: 'owner', viewValue: $localize`Owner`},
        {value: 'managingPartner', viewValue: $localize`Managing Partner`},
        {value: 'employee', viewValue: $localize`Employee`},
        {value: 'other', viewValue: $localize`Others`}
    ];
    rolesMap: LabelsMap = {
        ROLE_MASTER: $localize`administrator`,
        ROLE_REGULAR: $localize`User`
    };
    countryMap = {
        DE: $localize`germany`,
        AT: $localize`Austria`,
        CH: $localize`Switzerland`,
        LI: $localize`Liechtenstein`
    };
    countrySelect = [
        {value: 'DE', viewValue: $localize`Germany`},
        {value: 'AT', viewValue: $localize`Austria`},
        {value: 'CH', viewValue: $localize`Switzerland`},
        {value: 'LI', viewValue: $localize`Liechtenstein`}
    ];
    currencies = {
        EUR: 'EUR',
        CHF: 'CHF',
        EURCHF: $localize`EUR and CHF`
    };
    timeUnits: LabelsMap = {
        MONTHS: $localize`Months`,
        YEARS: $localize`Years`
    };
    financingModules = {
        machine: $localize`Machine`,
        vehicle: $localize`Vehicle`,
        realEstate: $localize`Real Estate`,
        property: $localize`Property`,
        workingCapital: $localize`Working Capital`,
        other: $localize`Other`,
        fastLane: $localize`Fast Lane`
    };
    financingSubmodules: {
        realEstate: { value: RealEstateModuleType, viewValue: string }[],
        property: { value: PropertyModuleType, viewValue: string }[],
    } = {
        realEstate: [
            {value: RealEstateModuleType.NEW_BUILDING, viewValue: $localize`New Building`},
            {value: RealEstateModuleType.EXISTING_BUILDING, viewValue: $localize`Existing Building`},
            {value: RealEstateModuleType.RENOVATION, viewValue: $localize`Renovation`},
            {value: RealEstateModuleType.FOLLOWUP_FINANCING, viewValue: $localize`Follow Up Financing`},
        ],
        property: [
            {value: 'FOLLOWUP_FINANCING', viewValue: $localize`Follow Up Financing`},
            {value: 'PURCHASE', viewValue: $localize`Purchase`},
        ]
    };
    addFinancingModule = {
        machine: $localize`Add Machine`,
        vehicle: $localize`Add Vehicle`,
        realEstate: $localize`Add Real Estate`,
        property: $localize`Add Property`,
        workingCapital: $localize`Add Working Capital`,
        other: $localize`Add Other`,
        fastLane: $localize`Add Fast Lane`,
    };
    inquiryMachineTypes: any[] = [
        {value: 'METALWORKING_MACHINES', viewValue: $localize`Metalworking Machines`},
        {value: 'WOODWORKING_MACHINES', viewValue: $localize`Woodworking Machines`},
        {value: 'PLASTIC_PROCESSING', viewValue: $localize`Plastic Processing Machines`},
        {value: 'PRINTING_MACHINES', viewValue: $localize`Printing Machines`},
        {value: 'MEDICAL_TECHNOLOGY', viewValue: $localize`Medical Technology`},
        {value: 'TOOL_MACHINES', viewValue: $localize`Tool Machines`},
        {value: 'ENERGY_TECHNOLOGY', viewValue: $localize`Energy Technology`},
        {value: 'TECHNOLOGY_FOR_RENEWABLE_ENERGIES', viewValue: $localize`Technology for Renewable Energies`},
        {value: 'CONSTRUCTION_MACHINERY_AND_EQUIPMENT', viewValue: $localize`Construction Machinery and Equipment`},
        {value: 'PACKAGING_MACHINES', viewValue: $localize`Packaging Machines`},
        {value: 'DISPOSAL_AND_RECYCLING_TECHNOLOGY', viewValue: $localize`Disposal and Recycling Technology`},
        {value: 'FOOD_PRODUCTION', viewValue: $localize`Food Production`},
        {value: 'WAREHOUSING_AND_LOGISTICS', viewValue: $localize`Warehousing and Logistics`},
        {value: 'AGRICULTURAL_MACHINERY', viewValue: $localize`Agricultural Machinery`},
        {value: 'HARDWARE_AND_SOFTWARE', viewValue: $localize`Hardware and Software`},
        {value: 'TELECOMMUNICATIONS_SYSTEMS', viewValue: $localize`Telecommunications Systems`},
        {value: 'OTHER_AND_TECHNICAL_EQUIPMENT', viewValue: $localize`Other Machinery and Technical Equipment`},
    ];
    /**
     * inquiryMachineTypes converted to key: value format, i.e.:
     * { METALWORKING_MACHINES: 'some readable label' }
     */
    inquiryMachineCategoryKeyToViewValue = this.inquiryMachineTypes.reduce((agg, category) => {
        agg[category.value] = category.viewValue;
        return agg;
    }, {});
    inquiryBuildingTypes: LabelsMap = {
        COMMERCIAL_BUILDING: $localize`Commercial Real Estate`,
        ADMINISTRATION: $localize`Administration`,
        PRODUCTION_LOGISTIC: $localize`Production/Logistics`,
        HOTEL: $localize`Hotel`,
        RETAIL: $localize`Consumer Market`,
        HOSPITALS_HEALTHCARE: $localize`Hospital/Healthcare Facilities`,
        MEDICAL_CENTER: $localize`Medical Practices`,
        CARE_HOME: $localize`Nursing Home`,
        OTHER: $localize`Other`,
        MIXED_USE: $localize`Mixed Use Real Estate`,
        RESIDENTIAL_USE: $localize`Residential Use Real Estate`
    };
    buildingTypeOfUseOptions = [
        {value: BuildingTypeOfUse.OFFICE, viewValue: $localize`Office`},
        {value: BuildingTypeOfUse.RETAIL, viewValue: $localize`Retail`},
        {value: BuildingTypeOfUse.HEALTH_CARE, viewValue: $localize`Health Care`},
        {value: BuildingTypeOfUse.HOTELS_HOSPITALITY, viewValue: $localize`Hotels & Hospitality`},
        {value: BuildingTypeOfUse.INDUSTRIES_LOGISTICS, viewValue: $localize`Industries & Logistics`},
        {value: BuildingTypeOfUse.GASTRONOMY, viewValue: $localize`Gastronomy`},
        {value: BuildingTypeOfUse.RESIDENTIAL_PROPERTY, viewValue: $localize`Residential property`},
    ];
    inquiryBuildingTypesV2: LabelsMap = {
        COMMERCIAL_BUILDING: $localize`Commercial Real Estate`,
        MIXED_USE: $localize`Mixed Use Real Estate`,
        RESIDENTIAL_USE: $localize`Residential Use Real Estate`
    };
    inquiryBuildingTypesQuickMode: any = {
        COMMERCIAL_USE: $localize`Commercial Use`,
        RESIDENTIAL_USE: $localize`Residetial Use`,
        MIXED_USE: $localize`Mixed Use`
    };
    inquiryEfficiencyStandards: any[] = [
        {value: 'UNKNOWN', viewValue: $localize`Currently unknown`},
        {value: 'KFW_E_40_PLUS', viewValue: $localize`KfW-Effizienzhaus 40+`},
        {value: 'KFW_E_40', viewValue: $localize`KfW-Effizienzhaus 40`},
        {value: 'KFW_E_55', viewValue: $localize`KfW-Effizienzhaus 55`},
        {value: 'KFW_E_70', viewValue: $localize`KfW-Effizienzhaus 70`},
        {value: 'KFW_E_85', viewValue: $localize`KfW-Effizienzhaus 85`},
        {value: 'KFW_E_100', viewValue: $localize`KfW-Effizienzhaus 100`},
        {value: 'KFW_E_115', viewValue: $localize`KfW-Effizienzhaus 115`}
    ];
    inquiryVehicleCategories: any[] = [
        {value: 'CAR', viewValue: $localize`Car`},
        {value: 'TRUCK', viewValue: $localize`Truck`},
        {value: 'SELF_PROPELLED_MACHINES', viewValue: $localize`Self-propelled machines`},
        {value: 'OTHERS', viewValue: $localize`Others`},
    ];
    inquiryVehicleCategoryKeyToViewValue = this.inquiryVehicleCategories.reduce((agg, category) => {
        agg[category.value] = category.viewValue;
        return agg;
    }, {});
    inquiryRealEstateCountries: any[] = [
        {value: 'GERMANY', viewValue: $localize`Germany`},
        {value: 'AUSTRIA', viewValue: $localize`Austria`},
        {value: 'SWITZERLAND', viewValue: $localize`Switzerland`},
        {value: 'LIECHTENSTEIN', viewValue: $localize`Liechtenstein`}
    ];
    /**
     * legacy ones - only for old inquiries.
     * See getInquiryFinancingTypes for the new ones.
     */
    inquiryFinancingTypes: any[] = [
        {value: FinancingType.CREDIT_LINE, viewValue: $localize`Credit Facility`},
        {value: FinancingType.LOAN, viewValue: $localize`Loan`}
    ];
    instituteTypeOptions: { value: InstituteType, viewValue: string }[] = [
        {value: InstituteType.BANK, viewValue: $localize`Bank`},
        {value: InstituteType.LEASING_AGENCY, viewValue: $localize`Leasing Agency`},
        {value: InstituteType.FACTORING_AGENCY, viewValue: $localize`Factoring Agency`},
        {value: InstituteType.OTHER, viewValue: $localize`Other`},
    ];
    bankTypeOptions: { value: BankType, viewValue: string }[] = [
        {value: BankType.KREISSPARKASSE, viewValue: $localize`Kreissparkasse`},
        {value: BankType.VOLKSBANK, viewValue: $localize`Volksbank`},
        {value: BankType.COMMERCIAL, viewValue: $localize`Commercial Banks`},
        {value: BankType.OTHER, viewValue: $localize`Other Banks`},
    ];
    mandateTypeOptions = [
        {value: 'BANK', viewValue: $localize`Bank`},
        {
            value: 'COMPANY',
            viewValue: $localize`Company`
        },
    ];
    showOtuaOptions = [
        {value: true, viewValue: $localize`Show one time usage agreement`},
        {value: false, viewValue: $localize`Show no one time usage agreement`},
    ];
    interestRateTypes: LabelsMap = {
        FIXED: $localize`Fixed`,
        FLOATING: $localize`Floating`
    };
    commitmentFeeModusOptions = [
        {value: 'totalCreditAmount', viewValue: $localize`Total credit amount`},
        {value: 'currentlyNotDrawn', viewValue: $localize`Currently not drawn credit amount`},
    ];
    inquiryRepaymentRhythms: any[] = [
        {value: 'MONTHLY', viewValue: $localize`Monthly`},
        {value: 'QUARTERLY', viewValue: $localize`Quarterly`},
        {value: 'HALF_YEARLY', viewValue: $localize`Semi Annually`},
        {value: 'YEARLY', viewValue: $localize`Anually`}
    ];
    inquiryPreferredAmortizationRates = [
        {value: 'amortizationRate', viewValue: $localize`amortization rate per interval`},
        {value: 'residualValue', viewValue: $localize`residual value by the end of period`},
    ];
    inquiryPreferredAnnuityRates = [
        {value: 'annuityRate', viewValue: $localize`annuity rate per interval`},
        {value: 'residualValue', viewValue: $localize`residual value by the end of period`},
    ];
    inquiryCommissionPayments = this.inquiryRepaymentRhythms;
    additionalLoanRepaymentRhythms: any[] = [
        {value: 'BULLET', viewValue: $localize`Bullet`},
    ];
    inquiryRepaymentTypes = [
        {value: 'REDEEMABLE_LOAN', viewValue: $localize`Redeemable Loan`},
        {value: 'ANNUITY_LOAN', viewValue: $localize`Annuity Loan`},
        {value: 'FINAL_LOAN', viewValue: $localize`Bullet Loan`},
        {value: 'NO_PREFERENCE', viewValue: $localize`No Preference`},
        {value: 'NOT_APPLICABLE', viewValue: '-'}
    ];
    inquiryGuaranteeTypes = [
        {value: 'RENT', viewValue: $localize`Rent guarantee`},
        {value: 'CONTRACT', viewValue: $localize`Contract guarantee`},
        {value: 'PERFORMANCE', viewValue: $localize`Performance guarantee`},
        {value: 'ADVANCE_PAYMENT', viewValue: $localize`Advance payment guarantee`},
        {value: 'TENDER', viewValue: $localize`Tender guarantee`},
    ];
    factoringTypes = [
        {value: 'FACTORING', viewValue: $localize`Factoring`},
        {value: 'REVERSE_FACTORING', viewValue: $localize`Reverse Factoring`},
    ];
    settingsUserTabMap = {
        ROLE_FINMATCH: $localize`FinMatch Users`,
        ROLE_BROKER: $localize`Users`,
        ROLE_COMPANY: $localize`Company Users`,
        ROLE_FINANCIAL: $localize`Bank Users & Settings`,
        ROLE_FINANCIAL_UNIT: $localize`Bank Organisation Users`
    };
    inquiryStates: { [key in InquiryState]: string } = {
        DRAFT: $localize`Draft`,
        FINMATCH_ASSESSMENT: $localize`FinMatch Assessment`,
        REVISION: $localize`Revision`,
        BANK_SELECTION: $localize`Bank Selection`,
        ACTIVE: $localize`Active`,
        WAITING_FOR_APPROVAL: $localize`Waiting for Approval`,
        WAITING_FOR_FINMATCH: $localize`Waiting for FinMatch`,
        SIGNING_OF_CONTRACTS: $localize`Signing of Contracts`,
        CLOSED: $localize`Closed`,
        CANCELED: $localize`Deactivated`,
        DELETED: $localize`Deleted`,

        // Frontend side only
        TO_ASSIGN: $localize`To Assign`,
        ASSIGNED: $localize`Assigned`,
        ACCEPTED: $localize`Accepted`,
        BANK_RESPONSE_COMPLETED: $localize`Bank Response Completed`,
        MATCHING_FAILED: $localize`Matching Failed`,
        REVIEW_OFFER_SELECTION: $localize`Review Offer Selection`,
        REVIEW_SIGNING: $localize`Review Signing`,

        // Secondary
        NEW: $localize`New`,
        OFFER_MADE: $localize`Offer Made`,
        REJECTED_BY_BANK: $localize`Rejected`,
    };
    companyStates: LabelsMap = {
        Registered: $localize`Pending`,
        Activated: $localize`Active`,
        Deleted: $localize`Deactivated`,
        SelectedForDeletion: $localize`Deactivated`
    };
    inquiryBankOfferStates = {
        NO_OFFER: $localize`No offer`,
        CREATED: $localize`No offer`,
        DRAFT: $localize`Draft Offer`,
        SUBMITTED: $localize`Offer Submitted`,
        ACCEPTED: $localize`Offer Accepted`,
        REJECTED: $localize`Offer Rejected`,
        REDRAFT: $localize`Redraft offer, not yet sent`,
        UNKNOWN: ''
    };
    productBankOfferStates: { [key in ProductBankOfferStatus]: string } = {
        CAPTURED: $localize`Captured`,
        SENT: $localize`Sent`,
        UPDATED: $localize`Updated`,
        PENDING: $localize`Pending`,
        NO_OFFER: $localize`No offer`,
    };
    inquiryStatesExplanations: LabelsMap = {
        DRAFT: $localize`Complete the draft of your inquiry and send it to FinMatch for final approval.`,
        FINMATCH_ASSESSMENT: $localize`Your inquiry is currently reviewed by our FinMatch telam of experts.`,
        REVISION: $localize`Your inquiry is not yet completed. Please, add the missing information.`,
        BANK_SELECTION: $localize`Matching banks list available. Please, choose the banks which should see your inquiry.`,
        ACTIVE: $localize`Your inquiry is active. Please, check your received bank offers.`,
        WAITING_FOR_FINMATCH: $localize`We review your selection of bank offers and contact you presently.`,
        SIGNING_OF_CONTRACTS: $localize`Inquiry successfully funded! The signing of contracts proceeds in coordination with your bank partners.`,
        REVIEW_SIGNING: $localize`Inquiry successfully funded! The signing of contracts proceeds in coordination with your bank partners.`,
        CLOSED: $localize`The contracts have been signed. We are looking forward to your next inquiry.`,
        CANCELED: $localize`The inquiry has been deactivated. Please create a new inquiry for your project.`,

        // Bank related explanations
        NEW_INQUIRY: $localize`You received a new inquiry. The company looks forward to receive your offer.`,
        OFFER_PLACE: $localize`You already placed an offer for this inquiry. You can edit it via the "Our Offer" tab.`,
        OFFER_REJECTED: $localize`The compnay accepted the offer of another financing partner.`,
        OFFER_ACCEPTED: $localize`Congratulations! The company accepted your offer. Please, make contact to the company to clarify the next steps.`
    };
    itemConditions: LabelsMap = {
        NEW: $localize`New`,
        USED: $localize`Used`,
        ALL_NEW: $localize`All new`,
        ALL_USED: $localize`All used`,
        PARTIALLY_USED: $localize`Partially used`
    };
    itemOrderedStates: LabelsMap = {
        NO: $localize`No`,
        YES: $localize`Yes`,
        PARTIALLY_ORDERED: $localize`Partially ordered`
    };
    financingDurationTypes: LabelsMap = {
        ESTABLISHED: $localize`Established`,
        UNTIL_FURTHER_NOTICE: $localize`Until further notice`
    };
    buildingUsageCategories: LabelsMap = {
        OWN_USAGE: $localize`Own use`,
        OTHER_USAGE: $localize`Third party use`
    };
    financingParametersCollaterals: LabelsMap = {
        BANK_STANDARD: $localize`Bank standard`,
        INDIVIDUAL: $localize`Individual`
    };
    collateralsKeyToLabels = {
        cessionCollateral: $localize`Cession`,
        mortgageCollateral: $localize`Mortgage`,
        personalGuaranteeCollateral: $localize`Personal guarantee`,
        chattelMortgageCollateral: $localize`Chattel mortgage`,
        pledgeAgreementCollateral: $localize`Pledge agreement`,
    };
    financingParametersDateMode: LabelsMap = {
        IMMEDIATE: $localize`Immediately`,
        CUSTOM: $localize`Select Date`
    };
    profiles = {
        saveSuccessfull: $localize`Profile information saved successfully`
    };
    inquiry = {
        saveSuccessfull: $localize`Inquiry has been saved`,
        saveAsDraftSuccessfull: $localize`Your inquiry has been saved as draft`,
        sendSuccessfull: $localize`Your inquiry has been sent to FinMatch. Wait for approval`,
        assignSuccessfull: $localize`Inquiry has been assigned`,
        acceptSuccessfull: $localize`Inquiry has been accepted`,
        cancelSuccessfull: $localize`Inquiry has been deactivated`,
        rejectSuccessfull: $localize`Inquiry has been rejected`,
        closeSuccessfull: $localize`Inquiry has been closed`,
        reOpenSuccessfull: $localize`Inquiry has been re-opened`,
        deleteSuccessfull: $localize`Inquiry has been deleted`,
        reviseSuccessfull: $localize`Inquiry has been returned for revision`,

        notFound: $localize`Inquiry with id [INQUIRY_ID] could not be found`,
        notFoundHeader: $localize`Inquiry not found`,

        removeModuleConfirmation: $localize`Do you really want to delete this module?`,
        deactivateInquiryModalHeader: $localize`Deactivate Inquiry - Confirmation`,
        deactivateInquiryConfirmation: $localize`Do you really want to irrevocably deactivate this inquiry?`,
        deactivateInquiryInformation: $localize`You will not be able to edit the inquiry, all related offers will be deleted and no new offers will be received.`,

        deleteInquiryModalHeader: $localize`Delete Inquiry - Confirmation`,
        deleteInquiryConfirmation: $localize`Do you really want to irrevocably delete this inquiry?`,
        // deleteInquiryInformation: this.i18n(
        //     'You will not be able to edit the inquiry, all related offers will be deleted and no new offers will be received.'
        // ),

        rejectInquiryModalHeader: $localize`Refuse Offer - Confirmation`,
        rejectInquiryConfirmation: $localize`Are you sure you want to irrevocably refuse this inquiry?`,
        rejectInquiryInformation: $localize`You will not be able to propose an offer for this inquiry afterwards.`,

        emptyModuleHint: $localize`Click on the Edit button to fill the module`,
        summaryDocumentsUpdateSuccess: $localize`Summary documents have been updated`,
        summaryDocumentsRemovalConfirmation: $localize`Do you really want to delete this file?`,
        acceptConfirmationHeader: $localize`Accept Inquiry - Confirmation`,
        acceptWithoutDocumentsUploadedConfirmation: $localize`Are you sure you don\'t want to add a summary to this financing request?`,
        sendConfirmation: $localize`Are you sure you want to send your inquiry? After sending the inquiry is analyzed by our FinMatch experts and you are not able to further edit it in the meantime.`,
        financingParametersConfirmation: $localize`Financing Parameters - Confirmation`,
        financingParametersMessage: $localize`The sum of the equity capital and the financing volume captured do not match the total  investment costs. If this is intended you can still send your inquiry, if not you can either  edit the investment costs, the equity investment or the financing volume on the level of  any module.`,
        sendConfirmationHeader: $localize`Send Inquiry - Confirmation`,
        reviseConfirmation: $localize`Should the inquiry really be given back to the corporate for Revision?`,
        reviseConfirmationHeader: $localize`Revise Inquiry - Confirmation`,
    };
    finmatchDashboard = {
        inquiriesNumberTooltip: $localize`Click on a cell to see the inquiries`
    };
    inquiryItem = {
        removeItemConfirmation: $localize`Do you really want to irrevocably delete this element?`
    };
    offer = {
        creationSuccessful: $localize`Offer proposal has been created`,
        saveSuccessful: $localize`Offer proposal has been saved`,
        acceptSuccessful: $localize`Selected offers have been accepted`,
        returnToCompanySuccessful: $localize`Offers have been returned to company`,
        selectionConfirmationSuccessful: $localize`Offers have been confirmed`
    };
    bank = {
        creationSuccessful: $localize`Bank has been created`,
        updateSuccessful: $localize`Bank has been updated`,
        deletionSuccessful: $localize`Bank has been deleted`,
        deactivationSuccessful: $localize`Bank has been deactivated`,
        reactivationSuccessful: $localize`Bank has been reactivated`,
        unitCreationSuccessful: $localize`Bank Unit has been created`,
        unitUpdateSuccessful: $localize`Bank Unit has been updated`,
        unitDeletionSuccessful: $localize`Bank Unit has been deleted`,
        proposalSentSuccessfully: $localize`Banks proposal has been sent`,
        selectionSuccessful: $localize`Bank selection has been saved`,
        contactsSelectionSuccessful: $localize`Your selection has been successfully saved`
    };
    user = {
        creationSuccessful: $localize`User has been created`,
        updateSuccessful: $localize`User has been updated`,
        deletionSuccessful: $localize`User has been deleted`,
    };
    company = {
        creationSuccessful: $localize`Company has been created`,
        updateSuccessful: $localize`Company has been updated`,
        deletionSuccessful: $localize`Company has been deleted`
    };
    broker = {
        deactivationSuccessful: $localize`Broker has been deactivated`,
        reactivationSuccessful: $localize`Broker has been reactivated`,
        invitationSuccessful: $localize`Invitation sent`,
    };
    websiteNavigation = {
        partners: $localize`Partners`,
        mission: $localize`Mission`,
        banks: $localize`Banks`,
        companies: $localize`Companies`,
        brokers: $localize`Brokers`,
        subbrokers: $localize`Sub-brokers`,
        notifications: $localize`Notifications`,
        messages: $localize`Messages`,
        bankAndOffers: $localize`Bank and Offers`,
        inquiries: $localize`Inquiries`
    };
    upload = {
        invalidExtensionHeader: $localize`Invalid file extension.`,
        invalidExtensionMessage: $localize`Allowed extensions: [EXTENSIONS].`,

        sizeExceededHeader: $localize`Selected file is too big.`,
        sizeExceededMessage: $localize`Maximum allowed file size is [FILE_SIZE].`,

        invalidContentTypeTitle: $localize`Invalid content type of requested file.`,
        invalidContentTypeMessage: $localize`Download prevented due to invalid content type of the requested file.`
    };
    changeNotPossible = $localize`Change is not possible`;
    lastAdminRoleChangeMsg = $localize`You cannot change the user role as at least one administrator needs to be assigned to the institute.  In order to change the user role please add another administrator user first.`;
    tutorialTexts = {
        userDetailsInfo: $localize`<h2>Details</h2><p>Right here you can see your name, the company name and also your current session time. If you click on&nbsp;&nbsp;<arrow id="tutorial-arrow">&#10095;</arrow>&nbsp;, you can access your personal user profile and also the profile of your company.</p>`,
        companyUsersLink: $localize`<h2>Company Users</h2><p>You can view all users, that are currently registered for your company.</p>`,
        inquiriesLink: $localize`<h2>Inquiries</h2><p>You can view and possibly edit all inquiries that have been created by your company.</p>`,
        startLink: $localize`<h2>Start</h2><p>This is your main dashboard, where you can find the last modified inquiries by your company (maximum of three inquiries displayed).</p>`,
    };
    teaserWidgets: {[key in TeaserWidget]?: string} = {
        [TeaserWidget.keyFigures]: $localize`Key figures`,
        [TeaserWidget.indicationRating]: $localize`Rating indication`
    };
    /**
     * Just some of the labels extracted from the templates so far,
     * since they turned out to be needed in other places (i.e. teaser).
     */
    moduleFields: { [key in InquiryModuleKey]: { [key: string]: { label: string, teaserLabel: string } } } = {
        machine: {
            quantity: {
                label: $localize`Quantity`,
                teaserLabel: $localize`Amount`,
            },
            totalSalesPrice: {
                label: $localize`Total purchasing price`,
                teaserLabel: $localize`Sales price`,
            },
            machineType: {
                label: $localize`Machine Basic Type`,
                teaserLabel: $localize`Machine Basic Type`,
            },
            emDeprecationPeriod: {
                label: $localize`Depreciation period`,
                teaserLabel: $localize`Depreciation period`,
            },
            emManufacturer: {
                label: $localize`Manufacturer`,
                teaserLabel: $localize`Manufacturer`,
            },
            emMachineType: {
                label: $localize`Type`,
                teaserLabel: $localize`Type`,
            },
            emDeliveryDate: {
                label: $localize`Delivery date`,
                teaserLabel: $localize`Delivery date`,
            },
            emCondition: {
                label: $localize`Condition`,
                teaserLabel: $localize`Condition`,
            },
            emUsedAge: {
                label: $localize`Age`,
                teaserLabel: $localize`Age`,
            },
            emUsedOriginalSalesPrice: {
                label: $localize`Original sales price (net)`,
                teaserLabel: $localize`Original sales price (net)`,
            },
            emPricePerUnit: {
                label: $localize`Purchase price per unit (net)`,
                teaserLabel: $localize`Purchase price per unit (net)`,
            }
        },
        vehicle: {
            quantity: {
                label: $localize`Quantity`,
                teaserLabel: $localize`Amount`,
            },
            category: {
                label: $localize`Category`,
                teaserLabel: $localize`Category`,
            },
            manufacturer: {
                label: $localize`Manufacturer`,
                teaserLabel: $localize`Manufacturer`,
            },
            type: {
                label: $localize`Type`,
                teaserLabel: $localize`Type`,
            },
            salesPricePerUnit: {
                label: $localize`Purchase price per unit (net)`,
                teaserLabel: $localize`Purchase price per unit (net)`,
            },
            totalSalesPrice: {
                label: $localize`Total volume (net)`,
                teaserLabel: $localize`Sales price`,
            },
        },
        realEstate: {},
        property: {
            propertyArea: {
                label: $localize`Property area`,
                teaserLabel: $localize`Property area`,
            },
            salesPrice: {
                label: $localize`Sales price (net)`,
                teaserLabel: $localize`Sales price`,
            },
            buyingCosts: {
                label: $localize`Buying costs (net)`,
                teaserLabel: $localize`Buying costs`,
            },
            totalCosts: {
                label: $localize`Total costs (net)`,
                teaserLabel: $localize`Total costs`,
            },
        },
        workingCapital: {
            financingVolume: {
                label: $localize`Financing volume (net)`,
                teaserLabel: $localize`Financing volume`,
            },
            description: {
                label: $localize`Description`,
                teaserLabel: $localize`Description`,
            },
        },
        other: {
            financingVolume: {
                label: $localize`Financing volume (net)`,
                teaserLabel: $localize`Total investment volume`,
            },
            description: {
                label: $localize`Description`,
                teaserLabel: $localize`Description`,
            },
        },
        fastLane: {
            financingVolume: {
                label: $localize`Financing volume (net)`,
                teaserLabel: $localize`Total investment volume`,
            },
            description: {
                label: $localize`Description`,
                teaserLabel: $localize`Description`,
            },
        }
    };
    brokerPartnerModels: { value: BrokerPartnerModel, viewValue: string }[] = [
        {value: BrokerPartnerModel.professionalPilot, viewValue: $localize`Professional pilot`},
        {value: BrokerPartnerModel.partner, viewValue: $localize`Partner`},
        {value: BrokerPartnerModel.expert, viewValue: $localize`Expert`},
        {value: BrokerPartnerModel.professional, viewValue: $localize`Professional`},
        {value: BrokerPartnerModel.professionalPlus, viewValue: $localize`Professional plus`},
        {value: BrokerPartnerModel.professionalExtra, viewValue: $localize`Professional extra`},
    ];
    brokerInquirySubmissionOptions: { value: BrokerInquirySubmissionType, viewValue: string }[] = [
        {value: BrokerInquirySubmissionType.toFinmatch, viewValue: $localize`to FinMatch`},
        {value: BrokerInquirySubmissionType.toMainBroker, viewValue: $localize`to main broker`},
        {value: BrokerInquirySubmissionType.toSuperordinateBroker, viewValue: $localize`to superordinate broker`},
    ];

    financialStatementTypes = {
        CURRENT_BUSINESS_ASSESSMENT: $localize`Business assessment`,
        ANNUAL_REPORT: $localize`Annual report`
    };
    inquiryModules = {
        vehicle: $localize`Vehicles`,
        realEstate: $localize`Real estate`,
        workingCapital: $localize`Working captial`,
        property: $localize`Property`,
        other: $localize`Others`,
        fastLane: $localize`Fast lanes`,
        machine: $localize`Machines`,
    };
    keyFigures = {
        assetStructure: $localize`Asset structure`,
        capitalStructure: $localize`Capital structure`,
        financialStrength: $localize`Financial strength`,
        profitability: $localize`Profitability`,
        liquidity: $localize`Liquidity`
    };

    constructor() {
    }

    removeStateConfirmationText = (state) => $localize`Do you really want to remove the state ${state} from your institutes investment profile?`;

    getInquiryFinancingTypes(moduleType: InquiryModuleType, moduleKey: InquiryModuleKey): { value: FinancingType, viewValue: string }[] {
        return [
            {value: FinancingType.LOAN, viewValue: $localize`Loan`},
            {
                value: FinancingType.CREDIT_LINE,
                viewValue: moduleType === RealEstateModuleType.EXISTING_BUILDING
                    ? $localize`Credit Line`

                    : $localize`Credit Line`
            },
            {value: FinancingType.LEASING, viewValue: $localize`Leasing`},
            {value: FinancingType.REAL_ESTATE_LEASING, viewValue: $localize`Real estate leasing`},
            {
                value: FinancingType.INSTALLMENT_PURCHASE,
                viewValue: (<InquiryModuleKey[]>['machine', 'vehicle']).includes(moduleKey)
                    ? `${$localize`Installment Purchase`} / ${$localize`Loan`}`
                    : $localize`Installment Purchase`
            },
            {value: FinancingType.GUARANTEE, viewValue: $localize`Guarantee`},
            {value: FinancingType.FACTORING, viewValue: $localize`Factoring`},
        ];
    }

    /**
     * @param isTeaser For the tesear there's no installment / loan purchase financing type.
     * It should be displayed as "Loan", instead.
     */
    getFinancingTypeOptionLabel(financingType: FinancingType, moduleType: InquiryModuleType, moduleKey: InquiryModuleKey, isTeaser = false): string {
        const lookupFinType = isTeaser
        && [FinancingType.INSTALLMENT_PURCHASE_LOAN, FinancingType.INSTALLMENT_PURCHASE].includes(financingType)
        && (['vehicle', 'machine'] as InquiryModuleKey[]).includes(moduleKey)
            ? FinancingType.LOAN
            : financingType;
        return this.getInquiryFinancingTypes(moduleType, moduleKey).find(option => option.value === lookupFinType).viewValue;
    }

    minLength(min): string {
        return $localize`Minimum ` + min + $localize` characters required.`;
    }

    maxLength(max): string {
        return $localize`Maximum ` + max + $localize` characters allowed`;
    }
}
